.App {
  text-align: center;
    background-color: #000;
    color: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Animations */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Animation on Load Page with Fade in */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Roll into page animation */
@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%) rotate(-540deg);
  }
  100% {
    opacity: 1;
    transform: translateX(0) rotate(0);
  }
}

/* Roll text in page animation */
@keyframes rollTextIn {
  0% {
    opacity: 0;
    transform: translateX(100%) rotate(540deg);
  }
  100% {
    opacity: 1;
    transform: translateX(0) rotate(0);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
/* Animation on Load Page with Fade in */
    animation: fadeIn 1s ease-in;
/* Roll into page animation */
    animation: rollIn 1s ease-in;

/* Roll text in page animation */
    animation: rollTextIn 1s ease-in;
}

.App-body {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    font-size: calc(10px + 2vmin);
    color: white;
/* Animation on Load Page with Fade in */
    animation: fadeIn 1s ease-in;

}

.App-footer {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    font-size: calc(10px + 2vmin);
    color: white;
/* Animation on Load Page with Fade in */
    animation: fadeIn 2s ease-in;
    /* Roll into page animation */
    animation: rollIn 1s ease-in;

    /* Roll text in page animation */
    animation: rollTextIn 1s ease-in;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
